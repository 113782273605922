
:root {
  --greyscale-background: rgb(17, 24, 39);
  --greyscale-primary: rgb(229, 231, 235);
  --greyscale-secondary: rgb(209, 213, 219);

  --blue-background: rgb(30, 58, 138);
  --blue-primary: rgb(191, 219, 254);
  --blue-secondary: rgb(147, 197, 253);

  --indigo-background: rgb(49, 46, 129);
  --indigo-primary: rgb(199, 210, 254);
  --indigo-secondary: rgb(165, 180, 252);

  --green-background: rgb(6, 78, 59);
  --green-primary: rgb(167, 243, 208);
  --green-secondary: rgb(110, 231, 183);

  --coolgrey-background: #1a2434;
  --coolgrey-primary: #f6f7f9;
  --coolgrey-secondary: #cfd2d9;

  --background-color: var(--coolgrey-background);
  --text-color-primary: var(--coolgrey-primary);
  --text-color-secondary: var(--coolgrey-secondary);
}

@keyframes background {
  10% {
    background-position: 0% 5%;
  }
  25% {
    background-position: 35% 50%;
  }
  50% {
    background-position: 95% 100%;
  }
  75% {
    background-position: 45% 65%;
  }
  90% {
    background-position: 0% 5%;
  }
}

html {
  font-size: 14px;
}

@media (min-width: 800px) {
  html {
    font-size: 16px;
  }
}

body {
  height: 100vh;
  color: var(--text-color-primary);
  background: linear-gradient(-45deg, var(--coolgrey-background), var(--blue-background), var(--indigo-background), var(--coolgrey-background));
  background-size: 400% 400%;
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  animation: background 60s ease infinite;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

div[role='main'] {
  position: fixed;
  width: 100%;
  height: 100%;
}

header {
  letter-spacing: 0.03rem;
  text-align: center;
  position: relative;
  top: 40%;
  transform: translateY(-40%);

  h1 {
    font-weight: 700;
    font-size: 2.6rem;
    line-height: 1.8;
  }

  h2 {
    font-weight: 300;
    font-size: 1.425rem;
    color: var(--text-color-secondary);
  }
}


.social {
  position: fixed;
  bottom: 8px;
  right: 16px;
  font-weight: 400;

  ul {
    list-style: none;
    text-align: center;
    margin: 0;
    display: flex;
    padding: 16px;

    li a {
      display: block;
      padding: 16px;
      color: var(--text-color-secondary);
      text-decoration: none;
      transition: color .2s ease-in-out;

      &:hover,
      &:focus {
        color: var(--text-color-primary);
        text-decoration: underline;
      }
    }
  }
}