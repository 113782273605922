:root {
  --greyscale-background: #111827;
  --greyscale-primary: #e5e7eb;
  --greyscale-secondary: #d1d5db;
  --blue-background: #1e3a8a;
  --blue-primary: #bfdbfe;
  --blue-secondary: #93c5fd;
  --indigo-background: #312e81;
  --indigo-primary: #c7d2fe;
  --indigo-secondary: #a5b4fc;
  --green-background: #064e3b;
  --green-primary: #a7f3d0;
  --green-secondary: #6ee7b7;
  --coolgrey-background: #1a2434;
  --coolgrey-primary: #f6f7f9;
  --coolgrey-secondary: #cfd2d9;
  --background-color: var(--coolgrey-background);
  --text-color-primary: var(--coolgrey-primary);
  --text-color-secondary: var(--coolgrey-secondary);
}

@keyframes background {
  10% {
    background-position: 0 5%;
  }

  25% {
    background-position: 35%;
  }

  50% {
    background-position: 95% 100%;
  }

  75% {
    background-position: 45% 65%;
  }

  90% {
    background-position: 0 5%;
  }
}

html {
  font-size: 14px;
}

@media (width >= 800px) {
  html {
    font-size: 16px;
  }
}

body {
  color: var(--text-color-primary);
  background: linear-gradient(-45deg, var(--coolgrey-background), var(--blue-background), var(--indigo-background), var(--coolgrey-background));
  background-size: 400% 400%;
  height: 100vh;
  font-family: Lato, Arial, Helvetica, sans-serif;
  animation: 60s infinite background;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

div[role="main"] {
  width: 100%;
  height: 100%;
  position: fixed;
}

header {
  letter-spacing: .03rem;
  text-align: center;
  position: relative;
  top: 40%;
  transform: translateY(-40%);

  & h1 {
    font-size: 2.6rem;
    font-weight: 700;
    line-height: 1.8;
  }

  & h2 {
    color: var(--text-color-secondary);
    font-size: 1.425rem;
    font-weight: 300;
  }
}

.social {
  font-weight: 400;
  position: fixed;
  bottom: 8px;
  right: 16px;

  & ul {
    text-align: center;
    margin: 0;
    padding: 16px;
    list-style: none;
    display: flex;

    & li a {
      color: var(--text-color-secondary);
      padding: 16px;
      text-decoration: none;
      transition: color .2s ease-in-out;
      display: block;

      &:hover, &:focus {
        color: var(--text-color-primary);
        text-decoration: underline;
      }
    }
  }
}

/*# sourceMappingURL=index.5d600f47.css.map */
